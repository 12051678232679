// import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { useState } from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import copy from "../images/copy.svg"
import about from "../images/about.png"
import map from "../images/map.svg"
import AboutHeader from "../images/header-about.svg"
import NewsHeader from "../images/header-news.svg"
import OrangcosongHeader from "../images/header-orangcosong.svg"
import UmedaHeader from "../images/header-umeda.svg"

import u1 from "../images/umeda_1.png"
import u2 from "../images/umeda_2.png"
import u3 from "../images/umeda_3.png"
import o1 from "../images/orangcosong_1.png"
import o2 from "../images/orangcosong_2.png"
import o3 from "../images/orangcosong_3.png"

import tw from "../images/icon-tw.svg"
import fb from "../images/icon-fb.svg"
import ig from "../images/icon-ig.svg"

const Section = styled.section`
  margin: auto;
  font-size: 1.4rem;
  line-height: 1.5;

  @media (min-width: 768px) {
    font-size: 1.6rem;
  }

  img {
    display: block;
  }

  #news-header {
    width: 24.5%;

    @media (min-width: 768px) {
      width: 16.33%;
    }
  }

  #about-header {
    width: 30%;

    @media (min-width: 768px) {
      width: 20%;
    }
  }

  .news {
    list-style: none;
    padding: 0px;
    margin-bottom: 5rem;

    &__item {
      margin-bottom: 1.5rem;

      &__header {
        cursor: pointer;
        display: flex;
        flex-direction: column;

        > div {
          background-color: rgb(243, 243, 243);
          width: 100%;
        }
      }

      &__title {
        font-size: 1.4rem;
        margin: 1rem 1.5rem 0px;
      }

      &__date {
        background-color: rgb(255, 187, 60);
        padding: 1rem;
        display: flex;
        width: 100px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }

      &__summary {
        margin: 0.5rem 1.5rem 1rem;
        font-size: 1.2rem;
      }

      &__body {
        background-color: rgb(243, 243, 243);
        border-top: 1px solid rgb(255, 187, 60);
        padding: 1rem 1.5rem;
        font-size: 1.4rem;

        h4,
        h5 {
          margin: 0;
        }

        h5 + .note {
          margin-top: 0;
        }

        a {
          color: #ff691f;
        }
      }

      .note {
        font-size: 1.2rem;
      }
    }

    @media (min-width: 768px) {
      &__item {
        &__header {
          flex-direction: row;
        }

        &__title {
          font-size: 1.6rem;
          margin: 0.5rem 1.5rem;
        }

        &__body {
          font-size: 1.6rem;
          padding: 2rem 3rem 1rem;
        }
      }
    }
  }

  .copy {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .access {
    margin: 3rem 0;
    display: flex;
    flex-direction: column;

    &__text {
      background-color: #f6b53d;
      width: 50%;
      padding: 2rem;
      border-radius: 2rem 2rem 0 0;
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        padding: 3rem;
      }

      &__header {
        margin: 1rem 0;

        &:first-of-type {
          margin-top: 0;
        }

        span {
          background-color: #fff;
          font-size: 1.4rem;
          font-weight: 400;
          padding: 0.25rem 0.5rem;
          margin-top: 0;

          @media (min-width: 768px) {
            font-size: 1.6rem;
          }
        }
      }

      p {
        margin: 0;

        &:last-of-type {
          margin-top: 2rem;
          font-size: 1.4rem;

          @media (min-width: 768px) {
            margin-top: auto;
          }
        }
      }
    }

    &__text,
    &__map {
      width: 100%;
    }

    @media (min-width: 768px) {
      max-width: 1200px;
      flex-direction: row;

      &__text {
        border-radius: 2rem 0 0 2rem;
      }

      &__text,
      &__map {
        width: 50%;
      }
    }
  }
`

const Work = styled.div`
  a {
    color: #000;
  }

  .note {
    font-size: 1.2rem;
  }

  .work {
    &__details {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      &__text,
      &__image {
        width: 100%;
      }

      &__profile__header {
        display: inline-block;
        font-weight: 400;
        border: 2px solid #000;
        border-radius: 1.75rem;
        padding: 0.5rem 1rem;
        margin-bottom: 0;
      }

      &__image {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 100%;
        }

        figcaption {
          text-align: right;
          font-size: 1rem;
          margin: 1rem 0;
        }
      }

      &__list {
        list-style: none;
        padding: 0;

        &__item {
          margin: 0;

          .button {
            display: inline-block;
            background-color: #ff691f;
            text-decoration: none;
            padding: 0.5rem 1rem;
            border-radius: 0.8rem;

            &:hover {
              background-color: #ffbc3c;
            }
          }

          @media (min-width: 768px) {
            margin: 1rem 0;
          }

          &--large {
            font-size: 1.6rem;

            .note {
              font-size: 1.4rem;
            }

            @media (min-width: 768px) {
              font-size: 2rem;
            }
          }
        }
      }

      &__time {
        font-weight: 400;
        margin-bottom: 0;

        &__list {
          font-size: 1.4rem;
        }
      }

      &__attention {
        background-color: #ffbc3c;
        font-weight: 400;
        margin: 0;
        padding: 1rem;
        display: inline-block;

        &__list {
          list-style-type: "※ ";
          padding: 0 0 0 1.5rem;
          font-size: 1.2rem;
        }
      }
    }

    &__artist {
      margin-top: 3rem;
      border-bottom: 2px solid #ff691f;

      #umeda-header {
        width: 50%;
      }

      #orangcosong-header {
        width: 65%;
      }

      @media (min-width: 768px) {
        #umeda-header {
          width: 30%;
        }

        #orangcosong-header {
          width: 40%;
        }
      }
    }

    &__description {
      padding-bottom: 2rem;
      border-bottom: 2px solid #ff691f;
    }

    &__title {
      font-size: 2rem;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 2.4rem;
      }
    }

    &__lead {
      margin: 0;
      font-size: 1.6rem;

      @media (min-width: 768px) {
        font-size: 1.8rem;
      }
    }

    &__movie {
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;
      margin-bottom: 5rem;

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .work {
      &__details {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &__text,
        &__image {
          width: 50%;
        }
      }
    }
  }
`

const Details = styled.div`
  display: flex;
  margin: 5rem 0;
  flex-direction: column;

  .about__details {
    &__header {
      font-size: 2rem;
      margin: 0 0 1rem;

      @media (min-width: 768px) {
        font-size: 2.4rem;
      }
    }

    &__text {
      width: 100%;
      background-color: #f4f4f4;
      border-radius: 2rem 2rem 0 0;
      padding: 2rem;

      @media (min-width: 768px) {
        padding: 3rem;
      }

      ul {
        list-style: none;
        padding: 0;
      }
    }

    &__credit {
      font-size: 1.2rem;
      margin: 2rem 0 0;

      @media (min-width: 768px) {
        font-size: 1.4rem;
        margin: 2rem 0;
      }
    }

    &__image {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .about__details {
      &__image,
      &__text {
        width: 50%;
      }

      &__text {
        border-radius: 2rem 0 0 2rem;
      }
    }
  }
`

const Footer = styled.footer`
  font-size: 1.2rem;
  padding: 2rem 0;
  margin: 0;

  p {
    margin: 0;
    padding: 1rem 0;
    border-top: 2px solid #ff691f;
    border-bottom: 2px solid #ff691f;
  }
`

const Sns = styled.div`
  position: relative;
  margin: 0 auto 4rem;
  width: 200px;
  height: 32px;
  display: flex;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    margin: 0 1rem;
  }
`

const newsData = [
  {
    id: 4,
    date: "2022.08.09",
    title: "梅田哲也「9月0才」申込受付中！",
    summary: "梅田哲也「9月0才」の申込を開始しました。",
    body: "<h4>インターネット予約はこのウェブサイトから可能です。</h4><p>下記の「9月0才」のページにある「申込はこちら」ボタンをクリックしてください。高槻現代劇場 窓口・お電話（072-671-9999 / 10:00～17:00 ※月曜休館・祝日除く）でもお申し込みいただけます。</p><h5>インターネットでのお申込みにあたって</h5><p class='note'>※Gmail宛のメールは自動返信メールが受信できない事象が確認されています。複数のメールアドレスをお持ちの場合は、Gmail以外での申し込みをお願いします。<br />※お使いの端末のセキュリティ設定により自動返信メールが受信ができない場合があります。「＠takatsuki-bsj.jp」ドメインからのメールを受信できるよう設定のうえお申込みください。</p>",
  },
  {
    id: 3,
    date: "2022.08.09",
    title: "「中高生のための放課後鑑賞ツアー」（9月22日）参加者募集！",
    summary:
      "高槻芸術時間「インタールード」では、中学生・高校生限定の鑑賞ツアーを行います。友達と一緒に梅田哲也さんの作品『9月0才』を鑑賞しませんか？不思議な現代アート作品に変貌した劇場を目撃してください。",
    body: "<h4>9月22日（木） 17:00〜18:30</h4><p>参加無料（要予約）</p><p>申込方法：高槻現代劇場 <a href='https://www.takatsuki-bsj.jp/contact/2062d9311827d6c/' target='_blank' rel='noreferrer'>予約フォーム</a><br />高槻現代劇場 窓口・お電話（072-671-9999 / 10:00～17:00 ※月曜休館・祝日除く）</p><h5>インターネットでのお申込みにあたって</h5><p class='note'>※Gmail宛のメールは自動返信メールが受信できない事象が確認されています。複数のメールアドレスをお持ちの場合は、Gmail以外での申し込みをお願いします。<br />※お使いの端末のセキュリティ設定により自動返信メールが受信ができない場合があります。「＠takatsuki-bsj.jp」ドメインからのメールを受信できるよう設定のうえお申込みください。</p>",
  },
  {
    id: 2,
    date: "2022.08.09",
    title: "「見えない人と見える人の対話鑑賞ツアー」（9月24日）参加者募集！",
    summary:
      "このツアーでは、見えない人、見えにくい人、見える人がグループになり、梅田哲也さんの作品『9月0才』を話しながら鑑賞します。鑑賞中は実際に起こっていること、見えているものを説明するだけでなく、匂い、音、触感などの感覚を研ぎ澄ませ、そのときに感じたこと、思ったことなども伝え合います。いつもとちがった鑑賞方法で作品を味わってみませんか？",
    body: "<h4>9月24日（土） 15:00～17:30<br /><span class='note'>※開始30分前に阪急高槻市駅改札口で待ち合わせが可能です。</span></h4><p>参加無料（要予約）</p><p>申込方法：高槻現代劇場 <a href='https://www.takatsuki-bsj.jp/contact/9662d9335f2deb6/' target='_blank' rel='noreferrer'>予約フォーム</a><br />高槻現代劇場 窓口・お電話（072-671-9999 / 10:00～17:00 ※月曜休館・祝日除く）<br />メール・・・apply★takatsuki-bsj.jp宛に、件名「インタールード対話鑑賞ツアー申込」として、本文に以下の項目を記入してお送りください。</p><p>①お名前、②電話番号、③視覚の障がいの有無、④介護者同伴の有無、⑤待ち合わせ希望の有無（開始30分前に阪急高槻市駅で待ち合わせが可能です）、⑥その他障がいの有無</p><p className='note'>※事務局からの返信メールをもって予約完了となります。<br />※送信の際は★を@に置き換えてください。</p><h5>インターネットでのお申込みにあたって</h5><p class='note'>※Gmail宛のメールは自動返信メールが受信できない事象が確認されています。複数のメールアドレスをお持ちの場合は、Gmail以外での申し込みをお願いします。<br />※お使いの端末のセキュリティ設定により自動返信メールが受信ができない場合があります。「＠takatsuki-bsj.jp」ドメインからのメールを受信できるよう設定のうえお申込みください。</p>",
  }
]

const AccordionItem = ({ news }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <li className="news__item">
      <div
        className="news__item__header"
        onClick={() => setIsActive(!isActive)}
      >
        <time className="news__item__date">{news.date}</time>
        <div>
          <h3 className="news__item__title">{news.title}</h3>
          <p className="news__item__summary">{news.summary}</p>
        </div>
      </div>
      {isActive && (
        <div
          className="news__item__body"
          dangerouslySetInnerHTML={{ __html: news.body }}
        />
      )}
    </li>
  )
}

const IndexPage = () => (
  <Layout>
    <Seo title="Inter-lude / 高槻芸術時間" />
    <Section>
      <img src={copy} alt="日時" className="copy" />
    </Section>
    <Section>
      <h2>
        <img src={NewsHeader} alt="News" id="news-header" />
      </h2>
      <ul className="news">
        {newsData.map(news => {
          return (
            <AccordionItem className="news__item" key={news.id} news={news} />
          )
        })}
      </ul>
    </Section>
    <Section>
      <h2>
        <img src={AboutHeader} alt="About" id="about-header" />
      </h2>
      <p>
        「インタールード（interlude）」とは、劇の「幕間」に上演される短い喜劇や音楽の「間奏」の意味です。
        2022年7月、高槻市の方々に長年親しまれてきた高槻現代劇場市民会館の幕が降り、
        2023年3月には高槻城公園芸術文化劇場の歴史が開幕します。
        その合間の8日間だけ、思い出と驚きが出会うアートの時間が現れます。
      </p>
      <Details className="about">
        <div className="about__details__text">
          <h3 className="about__details__header">
            高槻芸術時間「インタールード」について
          </h3>
          <p className="about__details__description">
            高槻城公園芸術文化劇場の開館記念プレイベントとして、高槻市、公益財団法人高槻市文化スポーツ振興事業団と京都芸術大学アートプロデュース学科が協働して開催します。
            文化施設の建て替えという50年に一度の「幕間」に、懐かしいのに新しい、古びているのに新鮮な芸術時間を創造し、これからの芸術文化を育て、
            市民活動を支援する高槻城公園芸術文化劇場への歴史につなげていきます。
          </p>
          <ul className="about__details__credit">
            <li>主 催：高槻市、公益財団法人高槻市文化スポーツ振興事業団</li>
            <li>共 催：京都芸術大学</li>
            <li>企 画：京都芸術大学アートプロデュース学科</li>
            <li>助 成：公益財団法人セゾン文化財団</li>
            <li>アーティスティック・ディレクター：山城大督（京都芸術大学）</li>
          </ul>
        </div>
        <div className="about__details__image">
          <img src={about} alt="インタールードについて" />
        </div>
      </Details>
      <Work className="work">
        <h3 className="work__artist">
          <img src={UmedaHeader} alt="梅田哲也" id="umeda-header" />
        </h3>
        <h4 className="work__title">『9月0才』</h4>
        <h5 className="work__lead">
          半世紀を超えて愛された旧劇場空間をめぐる、ツアー形式の展覧会
        </h5>
        <p className="work__description">
          世界各地で活躍する現代アーティスト梅田哲也が、閉館した高槻現代劇場
          市民会館（1964年開館）の館内を使い、この時だけの特別なツアー形式の展覧会を発表します。
          来場者は、不思議な現代アート作品に変貌した大ホール、元結婚式場、会議室、ロビーなどの様々な空間をガイドの誘導のもとで巡ります。
        </p>
        <div className="work__details">
          <div className="work__details__text">
            <ul className="work__details__list">
              <li className="work__details__list__item work__details__list__item--large">
                会期：9月17日(土)〜9月25日(日)
                <span className="note"> ※ 9月20日は休館</span>
              </li>
              <li className="work__details__list__item">
                会場：高槻現代劇場 市民会館
              </li>
              <li className="work__details__list__item">
                参加無料（事前予約制・先着順）
              </li>
              <li className="work__details__list__item">
                受付開始：8月9日（火）
              </li>
              <li className="work__details__list__item">
                申込方法：
                <a
                  href="https://www.takatsuki-bsj.jp/contact/1262c8f2c171e8e/"
                  className="button"
                  target="_blank"
                  rel="noreferrer"
                >
                  申込はこちら
                </a>
                <br />
                <span class="note">
                  または窓口、電話（072-671-9999 / 10:00～17:00）にて
                </span>
              </li>
            </ul>
            <h3 className="work__details__time">
              各日13:00〜18:40(下記の時間に出発)
            </h3>
            <p className="work__details__time__list">
              13:00〜 / 13:20〜 / 13:40〜 / 14:00〜 / 14:20〜 / 14:40〜 /
              15:00〜 / 15:40〜 / 16:00〜 / 16:20〜 / 16:40〜 / 17:00〜 /
              17:40〜 / 18:00〜 / 18:20〜 / 18:40〜
            </p>
            <h4 className="work__details__attention">
              ご参加にあたっての注意事項
            </h4>
            <ul className="work__details__attention__list">
              <li className="work__details__attention__list__item">
                歩きやすい服装でお越しください。
              </li>
              <li className="work__details__attention__list__item">
                インターネット、電話でのご予約は前日までにお願いいたします。
              </li>
              <li className="work__details__attention__list__item">
                ご参加当日は参加する回の10分前までに受付をお済ませください。
              </li>
              <li className="work__details__attention__list__item">
                当日の参加申込は、空きがある回に限り、昼12時より現地受付にて承ります。
              </li>
              <li className="work__details__attention__list__item">
                鑑賞時間の目安は約1時間です。
              </li>
              <li className="work__details__attention__list__item">
                未就学児入場可。ただし、小学生以下の場合は保護者同伴でお願いします。
              </li>
              <li className="work__details__attention__list__item">
                車椅子でのご来場や、ハンディキャップをお持ちの方はお申込の際にお知らせください。
              </li>
            </ul>
            <h4 className="work__details__profile__header">プロフィール</h4>
            <p className="work__details__profile__body">
              建物の構造や周囲の環境から着想を得たインスタレーションを制作し、美術館や博物館における展覧会のほかに、さまざまな空間や屋外において、
              その場所だけで成立する作品を展開する。パフォーマンスでは、普段行き慣れない場所へ観客を招待するツアー作品や、
              劇場の機能にフォーカスした舞台作品、中心点をもたない合唱のプロジェクトなどを国内外で発表。
              また先鋭的な音響のアーティストとしても国際的に知られている。近年のパフォーマンス作品に
              『Composite:Variations/Circle』（Kunstenfestivaldesarts2017、ブリュッセル、ベルギー）など。
              近年の展覧会に「さいたま国際芸術祭2020」（旧大宮区役所）、個展では「梅田哲也イン別府『O滞』」（別府、2020-2021年）、
              『うたの起源』（福岡市美術館、福岡、2019-2020年）など。
              <a href="https://siranami.com/" target="_blank" rel="noreferrer">
                https://siranami.com/
              </a>
            </p>
          </div>
          <div className="work__details__image">
            <figure>
              <img src={u1} alt="umeda1" />
              <figcaption>Photo:Yuko Amano</figcaption>
            </figure>
            <figure>
              <img src={u2} alt="umeda2" />
            </figure>
            <figure>
              <img src={u3} alt="umeda3" />
              <figcaption>
                『O階』（さいたま国際芸術祭2020）
                <br />
                Photo:MARUO Ryuichi
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="work__movie">
          <iframe
            src="https://www.youtube.com/embed/cMgAubY7zy4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Work>
      <Work className="work">
        <h3 className="work__artist">
          <img
            src={OrangcosongHeader}
            alt="orangcosong"
            id="orangcosong-header"
          />
        </h3>
        <h4 className="work__title">『演劇クエスト 高槻編』</h4>
        <h5 className="work__lead">
          ひとりひとりが冒険者。「冒険の書」を手にプレイする、高槻の町を歩く不思議な体験。
        </h5>
        <p className="work__description">
          「冒険の書」に書かれた選択肢を手がかりに、観客ひとりひとりが町の中を歩いて物語を進めていく、自由度の高い遊歩型ツアープロジェクト。世界各地で創作されてきたユニークな作品の高槻バージョンです。まずは高槻現代劇場
          市民会館で配布される「冒険の書」を手に入れてプレイしよう。高槻の町をさまよう不思議な時間が始まります。幸運を祈る！
        </p>
        <div className="work__details">
          <div className="work__details__text">
            <ul className="work__details__list">
              <li className="work__details__list__item work__details__list__item--large">
                「冒険の書」配布期間：
                <br />
                9月17日(土)〜9月25日(日)各日12:00〜19:00
                <span className="note"> ※ 9月20日は休館</span>
              </li>
              <li className="work__details__list__item">
                配布場所：高槻現代劇場 市民会館
                <br />
                参加無料（申込不要）
              </li>
            </ul>
            <h4 className="work__details__attention">
              ご参加にあたっての注意事項
            </h4>
            <ul className="work__details__attention__list">
              <li className="work__details__attention__list__item">
                歩きやすい服装でお越しください
              </li>
              <li className="work__details__attention__list__item">
                冒険できるのは昼間のみです。日没以降に「冒険の書」を手に入れた場合は、翌日以降のプレイをお願いします。
              </li>
              <li className="work__details__attention__list__item">
                プレイ時間の目安は、第一章（チュートリアル）は約30分。
                <br />
                それ以降の章については「冒険の書」に記載いたします。
              </li>
              <li className="work__details__attention__list__item">
                鑑賞時間の目安は約1時間です。
              </li>
              <li className="work__details__attention__list__item">
                アーティストや他の冒険者たちと直接対話する感想シェア会も予定しています。
                <br />
                詳細はインタールードのSNSやハッシュタグ #engekiquest
                をチェックしてください。
              </li>
            </ul>
            <h4 className="work__details__profile__header">プロフィール</h4>
            <p className="work__details__profile__body">
              横浜を拠点に世界各地で活動する藤原ちからと住吉山実里によるアート・コレクティブ。名称はインドネシア語の
              orang（人）とkosong（空っぽ）から。演劇やダンスなど舞台芸術の経験をベースにしながらも、ジャンルにこだわらず、
              プロジェクトごとに様々な他者と結びついて創作を行っている。
              「冒険の書」を手にして町を歩く遊歩型ツアープロジェクト『演劇クエスト』（2014−）は、これまで横浜、城崎、マニラ、
              デュッセルドルフ、安山、香港、東京、バンコク、ローザンヌ、マカオ、妙高、マカンダで展開されてきた。そのほか、
              多国籍のアーティストたちとつくりあげるテーブルパフォーマンス『IsLand
              Bar』（2017−）、完全な沈黙のもと筆談のみで対話する『筆談会』（2017−）など。
              <a
                href="https://orangcosong.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://orangcosong.com/
              </a>
            </p>
          </div>
          <div className="work__details__image">
            <figure>
              <img src={o1} alt="orangcosong1" />
              <figcaption>Photo:加藤甫</figcaption>
            </figure>
            <figure>
              <img src={o2} alt="orangcosong2" />
              <figcaption>
                『演劇クエスト メトロポリスの秘宝』
                <br />
                （東京都現代美術館｜2019）
              </figcaption>
            </figure>
            <figure>
              <img src={o3} alt="orangcosong3" />
              <figcaption>
                『演劇クエスト 虹の按摩師』 <br />
                （Courtesy of Hong Kong Arts Center｜2018）
              </figcaption>
            </figure>
          </div>
        </div>
      </Work>
    </Section>
    <Section>
      <div className="access">
        <div className="access__text">
          <h3 className="access__text__header">
            <span>会場</span>
          </h3>
          <p>
            高槻現代劇場市民会館
            <br />
            〒569-0077 高槻市野見町2-33
          </p>
          <h3 className="access__text__header">
            <span>アクセス</span>
          </h3>
          <p>
            阪急京都線「高槻市駅」から徒歩5分
            <br />
            JR京都線「高槻駅」から徒歩12分
          </p>
          <p className="access__text__description">
            1964年に開館し長年に渡り地域の文化・芸術の拠点として親しまれてきた高槻現代劇場
            市民会館は、2022年7月31日をもって閉館しました。その歴史は2023年3月に開館する高槻城公園芸術文化劇場に引き継がれます。
          </p>
        </div>
        <div className="access__map">
          <img src={map} alt="map" />
        </div>
      </div>
    </Section>
    <Footer>
      <p>
        グラフィックデザイン：いすたえこ　メインビジュアル写真：増田好郎　メインビジュアル衣装：山本哲也（POTTO）　モデル：
        jennifer　ヘア&amp;メイク：Nagisa　WEB：HAUS
      </p>
    </Footer>
    <Sns>
      <a href="https://www.instagram.com/interlude_info/">
        <img src={ig} alt="Instagram" />
      </a>
      <a href="https://twitter.com/interlude_info">
        <img src={tw} alt="Twitter" />
      </a>
      <a href="https://www.facebook.com/105728075480381/">
        <img src={fb} alt="facebook" />
      </a>
    </Sns>
  </Layout>
)

export default IndexPage
